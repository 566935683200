<template>
  <div class="hot-act">
    <div class="hot-act-title" v-show="comObj.showTitle">
      <span>{{comObj.headline}}</span>
    </div>
    <div class="hot-act-list" v-show="comObj.activityNum == 4">
      <div
        class="hot-act-list-item"
        v-for="(item, idx) in comObj.data"
        :key="idx"
        @click.stop="setOption(item, idx)"
      >
        <div class="hot-act-list-top">
          <img :src="item.imgUrl" alt />
        </div>
        <div class="hot-act-list-bot" :style="{background:item.background}">
          <span>{{item.title}}</span>
          <div>{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="hot-act-list" style="padding: 0 6px; margin-top:0" v-show="comObj.activityNum == 2">
      <div
        class="hot-act-list-item TwoStyle"
        v-for="(item, idx) in comObj.data"
        :key="idx"
        @click.stop="setOption(item, idx)"
      >
        <div class="hot-act-list-bot TwoStyle-list-bot">
          <span>{{item.title}}</span>
          <div>{{item.text}}</div>
        </div>
        <div class="hot-act-list-top TwoStyle-list-top">
          <img :src="item.imgUrl" alt />
        </div>
      </div>
    </div>
    <div class="hot-act-list" style="padding: 0 6px; margin-top:0" v-show="comObj.activityNum == 1">
      <div
        class="hot-act-list-item TwoStyle TwoStyle_one"
        v-for="(item, idx) in comObj.data"
        :key="idx"
        @click.stop="setOption(item, idx)"
      >
        <div class="hot-act-list-bot TwoStyle-list-bot TwoStyle_one-list-bot">
          <span>{{item.title}}</span>
          <div>{{item.text}}</div>
        </div>
        <div class="hot-act-list-top TwoStyle-list-top TwoStyle-list-top">
          <img :src="item.imgUrl" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comObj", "nowComIdx"],
  computed: {
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.comObj);
  },
  methods: {
    // 获取点击的组件设置
    setOption(item, idx) {
      console.log(item, idx);
      
    }
  }
};
</script>
<style lang="scss" scoped>
.hot-act {
  height: auto;
  background: transparent;
  margin-bottom: 5px;
  .hot-act-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    background: url("../../../../public/static/images/home/title-bg.png") no-repeat;
    background-size: 50%;
    background-position-y: 28%;
    background-position-x: 50%;
  }
  .hot-act-list {
    margin-top: -10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .edit {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 7px;
      right: 7px;
      display: none;
    }
    .hot-act-list-item {
      width: 85px;
      height: auto;
      margin-top: 10px;
      margin-left: 6px;
      background: #ffffff;
      position: relative;
      .hot-act-list-top {
        width: 100%;
        height: 80px;
        padding: 9px 6px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .hot-act-list-bot {
        width: 100%;
        height: 42.5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        color: #ffffff;
        padding-top: 4px;
        background: linear-gradient(
          -90deg,
          rgba(171, 6, 229, 1) 0%,
          rgba(222, 89, 246, 1) 100%
        );
        div {
          width: 70px;
          height: 12px;
          font-size: 10px;
          background: #ffffff;
          color: #a328e0;
          text-align: center;
          border-radius: 12px;
          font-weight: 500;
          margin-top: 5px;
          vertical-align: middle;
        }
      }
    }

    .TwoStyle {
      border-bottom: 1px solid #e5e5e5;
      width: 50%;
      display: flex;
      align-items: center;
      margin: 0;

      .TwoStyle-list-bot {
        flex: 1;
        background: transparent;
        color: #ef1616;
      }
      .TwoStyle-list-top {
        flex: 1;
        padding: 0;
        height: auto;
        img {
          width: 90%;
          height: 80%;
        }
      }
    }
    .TwoStyle_one {
      width: 100% !important;
      .TwoStyle_one-list-bot {
        font-size: 20px;
        height: auto;
        div {
          height: auto;
          width: 100%;
          font-size: 18px;
        }
      }
      .TwoStyle-list-top {
        height: 80px !important;
        padding: 9px 6px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .hot-act-list-item:hover .edit {
      display: block;
    }
  }
}
</style>
